







































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import { deleteFormById, getFormById, getPreviewImage, updateFormById, updateFormStatusById, updateFormTypeById } from '@/api/leadplus/forms'
import { getList, getListCustomFields } from '@/api/consoleApi/recipients'
import VsFormEditor from '@/modules/leadplus/components/VsFormEditor/Index.vue'
import VsFogFormPreviewModal from '@/modules/leadplus/components/VsFogFormPreviewModal/Index.vue'
import IntegrationFormModal from '@/modules/leadplus/components/IntegrationFormModal/Index.vue'
import DuplicateFormModal from '@/modules/leadplus/components/DuplicateFormModal/Index.vue'
import { AppModule } from '@/store/modules/app'
import { CustomField } from '@/utils/customFields'
import { fieldsWithDifferentType, missingRequiredFields, notExistingFields } from '@/utils/leadplus'
import type { LeadplusForm } from '@/api/leadplus/types'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import FormNameModal from '@/modules/leadplus/components/FormNameModal/Index.vue'
import { get } from 'lodash'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'

@Component({
    name: 'VsLeadplusFormEdit',
    components: {
        VsContainer,
        VsSectionHeader,
        VsLoader,
        VsConfirm,
        VsDropdownButton,
        VsCollapseCard,
        VsActionCard,
        VsOverlayCard,
        VsHintCard,
        VsInlineErrorsCheck,
        VsFogFormPreviewModal,
        IntegrationFormModal,
        DuplicateFormModal,
        VsFormEditor,
        FormNameModal,
        VsSeparator,
        VsTabsHeading,
    },
})
export default class extends Vue {
    loading = false
    showRequiredFields = false
    showMissingFields = false
    showFieldsWithDifferentType = false
    tabOpen = ''
    form: LeadplusForm | null = null
    list: any = null
    customFields: CustomField[] = []
    updateIfDuplicate = 'update'
    $refs!: any
    previewImage: any = null

    get formId () {
        return this.$route.params.formId
    }

    get listName () {
        return this.list?.name || 'lista non trovata'
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get requiredFields () {
        return this.customFields.filter(el => el.validation.required)
    }

    get hintCardStatus () {
        return this.missingRequiredFields.length === 0 && this.fieldsWithDifferentType.length === 0 ? 'success' : 'error'
    }

    get requiredFormStatus () {
        return this.missingRequiredFields.length === 0 ? 'success' : 'error'
    }

    get fieldsWithDifferentType () {
        if (!this.form || !this.form.pages[0] || !this.customFields || this.customFields.length === 0) return []
        return fieldsWithDifferentType(this.form.pages[0].fields, this.customFields)
    }

    get contentStatus () {
        if (!this.form?.theme.type) return 'default'

        return this.hintCardStatus
    }

    get domainCheck () {
        if (!this.form) return false
        return this.form.flux.urlRules.length > 0
    }

    get notExistingFields () {
        if (!this.form || !this.form.pages[0] || !this.customFields || this.customFields.length === 0) return []
        return notExistingFields(this.form.pages[0].fields, this.customFields)
    }

    get missingRequiredFields () {
        if (!this.form || !this.customFields || this.customFields.length === 0) return []
        return missingRequiredFields(this.form.pages, this.customFields)
    }

    get formType () {
        switch (this.form?.theme?.type) {
            case 'popover':
                return 'Pop up'
            case 'embedded':
                return 'Embedded form'
            case 'topbar':
                return 'Top bar'
            case 'scrollbox':
                return 'Scroll box'
            default:
                return this.form?.theme?.type
        }
    }

    get formTypes () {
        return [
            {
                title: 'Embedded Form',
                subtitle: 'Questo tipo di form potrà essere inserito sopra, sotto o in un punto specifico della tua pagina',
                value: 'embedded',
                src: require('@/assets/img/leadplus/embedded.jpg'),
            },
            {
                title: 'Top Bar',
                subtitle: 'Scegli se mostrare il form sulla parte alta o bassa della tua pagina',
                value: 'topbar',
                src: require('@/assets/img/leadplus/topbar.jpg'),
            },
            {
                title: 'Pop up',
                subtitle: 'Finestra che viene aperta sullo schermo durante la navigazione della pagina.',
                value: 'popover',
                src: require('@/assets/img/leadplus/popover.jpg'),
            },
            {
                title: 'Scroll Box',
                subtitle: 'In basso a sinistra o in basso a destra della tua pagina. Il modo migliore per essere coinvolgenti ma discreti.',
                value: 'scrollbox',
                src: require('@/assets/img/leadplus/scrollbox.jpg'),
            },
        ]
    }

    get tabs () {
        return [
            {
                label: 'Riepilogo',
                id: 'editForm',
                to: {
                    name: 'editForm',
                    params: {
                        formId: this.formId,
                    },
                },
            },
            {
                label: 'Statistiche',
                id: 'formStatistics',
                to: {
                    name: 'formStatistics',
                    params: {
                        formId: this.formId,
                    },
                },
            },
        ]
    }

    get activeTab () {
        return this.$route.name
    }

    @Watch('formId', { immediate: true })
    async getForm () {
        this.loading = true
        try {
            const resp = await getFormById(this.formId)
            this.form = resp.data
            await this.getList(resp.data.foreign_recipient_id)
            await this.getCustomFields()
            this.getPreviewImage()
        } catch (e) {
            console.log(e)
            this.form = null
            const status = get(e, 'response.data.statusCode', 0)
            const message = get(e, 'response.data.message', [''])[0]
            if (status === 404 || (status === 400 && message === 'formId must be a mongodb id')) {
                this.$root.$vsToast({
                    heading: 'Form non trovato',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
                this.$router.replace({
                    name: 'leadplusForms',
                })
            }
        }
        this.loading = false
    }

    async getPreviewImage () {
        try {
            const res = await getPreviewImage(this.formId, {
                t: Date.now(),
            })
            this.previewImage = URL.createObjectURL(res.data)
        } catch (e) {
            this.previewImage = null
        }
    }

    loaded () {
        if (this.previewImage) URL.revokeObjectURL(this.previewImage)
    }

    async deleteForm () {
        try {
            await this.$refs.confirmFormDelete.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            await deleteFormById(this.formId)
            this.$router.replace({
                name: 'leadplusForms',
            })

            this.$root.$vsToast({
                heading: 'Form cancellato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del form',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async getList (listId: string) {
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
        } catch (e) {
            this.list = null
            console.log(e)
        }
    }

    private async getCustomFields () {
        try {
            const cf: any[] = []
            await this.loopCall(1, cf, getListCustomFields)
            this.customFields = cf
            // this.loadForm()
        } catch (e) {
            console.log(e)
            this.customFields = []
        }
    }

    async loopCall (page: number, values: any[], caller: any) {
        const resp = await caller(this.form?.foreign_recipient_id || '', {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    async setFormType (formType: string) {
        try {
            await updateFormTypeById(this.formId, { type: formType })
            await this.getForm()
            if (formType) {
                this.$refs.vsFormEditor.openModal(this.form, this.customFields)
                this.$root.$vsToast({
                    heading: 'Tipologia salvata con successo',
                    aspect: VsToastAspectEnum.success,
                    timeout: 3000,
                })
            } else {
                this.$root.$vsToast({
                    heading: 'Contenuto cancellato con successo',
                    aspect: VsToastAspectEnum.success,
                    timeout: 3000,
                })
            }
        } catch (e) {
            console.log(e)
            let heading = 'Errore durante la modifica della tipologia'
            if (!formType) {
                heading = 'Errore durante la cancellazione del contenuto'
            }
            this.$root.$vsToast({
                heading,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    async setActive (isActive: boolean) {
        try {
            await updateFormStatusById(this.formId, {
                isActive,
            })
            this.$root.$vsToast({
                heading: `Form ${isActive ? 'attivato' : 'disattivato'} con successo`,
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getForm()
        } catch (e) {
            this.$root.$vsToast({
                heading: `Errore durante ${isActive ? 'l\'attivazione' : 'la disattivazione'} del form`,
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async deleteContent () {
        try {
            await this.$refs.deleteContentConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.setFormType('')
    }

    openAdvanced () {
        this.updateIfDuplicate = this.form?.update_if_duplicate ? 'update' : 'notUpdate'
        this.tabOpen = 'advanced'
    }

    async saveAdvanced () {
        try {
            await updateFormById(this.formId, {
                update_if_duplicate: this.updateIfDuplicate === 'update',
            })
            this.$root.$vsToast({
                heading: 'Impostazioni avanzate salvate con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            await this.getForm()
            this.tabOpen = ''
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio delle impostazioni avanzate',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async cleanFields (form: LeadplusForm, customFields: CustomField[]) {
        this.loading = true
        try {
            await updateFormById(form._id, {
                pages: form.pages.map((page: any) => {
                    return {
                        ...page,
                        fields: page.fields.filter((field: any) => {
                            return customFields.find(el => field.fieldId === el.id)
                        }),
                    }
                }),
            })
            this.$root.$vsToast({
                heading: 'Campi cancellati con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getForm()
            this.showMissingFields = false
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dei campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    openFieldsModal () {
        this.$refs.vsFormEditor.openModal(this.form, this.customFields)
        this.showFieldsWithDifferentType = false
    }

    formCreated (form: LeadplusForm) {
        this.$router.push({
            name: 'editForm',
            params: {
                formId: form._id,
            },
        })
    }
}
